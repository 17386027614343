
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from "@mui/material";
import { ALL_CHAPTERS } from '../../constants';
import "../../styles/header.css"

export default function Header(props : any) {
    const chapterSelected : string | undefined = props.chapterSelected
    const classAdd : string = props.classAdd
    const classBigAdd : string = props.classBigAdd
    const pageSubtitle : string[] = props.pageSubtitle
    const setChapterSelected : React.Dispatch<React.SetStateAction<string | undefined>> = props.setChapterSelected
    const tooNarrow : boolean = props.tooNarrow
    const tooSmall : boolean = props.tooSmall
    const tooBig : boolean = props.tooBig

    let chapIndex = (ALL_CHAPTERS.length - 1);
    for (let i = 0; i < ALL_CHAPTERS.length; i++) {
        if (chapterSelected === ALL_CHAPTERS[i].name){
            chapIndex = i;
            break;
        }
    }

    return(
        <div className={`header${classAdd}`}>
            <span  className={`version${classBigAdd}`} style={{ color: "white", right: "0", position: "absolute"}}>V 1.1</span>
            {!tooNarrow &&
                <>
                    {!tooSmall &&
                    <img className= "ammoicon"src="ammo_op.png" alt="ammoop" />
                    }
                    {tooSmall && !chapterSelected &&
                    <img className= {`ammoicon${classAdd}`}src="ammo_op.png" alt="ammoop" />
                    }
                    {tooSmall && chapterSelected &&
                    <img className= {`ammoicon${classAdd}`}src="ammooplogo.png" alt="ammooplogo" />
                    }
                </>
            }
            {tooNarrow && !chapterSelected &&
                <img className= {`ammoicon${classAdd}`}src="ammo_op.png" alt="ammoop" />
            }
            {chapterSelected &&
                <>
                    {!tooSmall && !tooNarrow && !tooBig &&
                        <><Button variant="contained" sx={{float: "left", marginLeft: "10px", position: "absolute", height: "fit-content", width: "fit-content", top: "22px",
                            backgroundColor: "#D49F00", "&:hover, &.Mui-focusVisible": { backgroundColor: "#D49F00" }, cursor: 'pointer'}} onClick={() => {setChapterSelected(undefined)}}
                            startIcon={<ArrowBackIcon />}>Change
                        </Button>
                        <p style={{position: "absolute", left: "150px", top: "12px"}}>
                            <span style={{color: "white"}}>{`${pageSubtitle[0]}`}</span>
                            <span style={{color: ALL_CHAPTERS[chapIndex].color}}>{`${pageSubtitle[1]}`}</span>
                        </p>
                        </>
                    }
                    {tooSmall && !tooNarrow && !tooBig &&
                        <><Button variant="contained" sx={{float: "left", marginLeft: "10px", position: "absolute", height: "fit-content", width: "fit-content", top: "26px",
                            backgroundColor: "#D49F00", "&:hover, &.Mui-focusVisible": { backgroundColor: "#D49F00" }, cursor: 'pointer'}} onClick={() => {setChapterSelected(undefined)}}
                            startIcon={<ArrowBackIcon />}>Change
                        </Button>
                        <div style={{position: "absolute", left: "15px", bottom: "8px"}}>
                            <span style={{color: ALL_CHAPTERS[chapIndex].color}}>{`${pageSubtitle[1]}`}</span>
                        </div>
                        </>
                    }
                    {tooNarrow && !tooSmall && !tooBig &&
                        <><Button variant="contained" sx={{float: "left", marginLeft: "10px", position: "absolute", height: "fit-content", width: "fit-content", top: "15px",
                            backgroundColor: "#D49F00", "&:hover, &.Mui-focusVisible": { backgroundColor: "#D49F00" }, cursor: 'pointer'
                            }} onClick={() => { setChapterSelected(undefined); } }startIcon={<ArrowBackIcon />}>Change</Button>
                        <div style={{position: "absolute", left: "15px", bottom: "4px"}}>
                            <span style={{color: ALL_CHAPTERS[chapIndex].color}}>{`${pageSubtitle[1]}`}</span>
                        </div>
                        </>
                    }
                    {/* 4k */}
                    {tooBig && !tooSmall && !tooNarrow &&
                        <><Button variant="contained" sx={{ float: "left", marginLeft: "0.26vw", position: "absolute", height: "fit-content", width: "8.151vw", top: "1.019vh",
                            backgroundColor: "#D49F00", "&:hover, &.Mui-focusVisible": { backgroundColor: "#D49F00" }, cursor: 'pointer', fontSize: "1.25vw"}} onClick={() => { setChapterSelected(undefined); }} 
                            startIcon={<ArrowBackIcon style={{ fontSize: "64px" }} />}>Change
                        </Button>
                        <p style={{ position: "absolute", left: "8.464vw", top: "1.296vh" }}>
                            <span className={`subtitle${classBigAdd}`} style={{ color: "white", marginLeft: "1vw", fontSize: "40px" }}>{`${pageSubtitle[0]}`}</span>
                            <span className={`subtitleBranch${classBigAdd}`} style={{ color: ALL_CHAPTERS[chapIndex].color, fontSize: "40px" }}>{`${pageSubtitle[1]}`}</span>
                        </p>
                        </>
                    }
                </>
            }
            
            {/* Route buttons to the edit page(s) below */}
            <Button variant="contained" sx={{overflow: "initial", width: "fit-content", position: "absolute", top: "22px", right: "230px", height: "fit-content",
            float: "right", borderRadius: "5px", backgroundColor: "#D49F00", "&:hover, &.Mui-focusVisible": { backgroundColor: "#D49F00" }, cursor: 'pointer'}}
            href="/edit">+ UPDATE STRAC</Button>
            {/* <Button variant="contained" sx={{overflow: "initial", width: "fit-content", position: "absolute", top: "22px", right: "20px", height: "fit-content",
            float: "right", borderRadius: "5px", backgroundColor: "#D49F00", "&:hover, &.Mui-focusVisible": { backgroundColor: "#D49F00" }, cursor: 'pointer'}}
            href="/edit">+ NEW TRAINING</Button> */}
        </div>
    )
}
