import { ALL_CHAPTERS, ARMY_DODICS, MODAL_STYLE_FIT_CONTENT, STR_PROJECT_MANAGER_EMAIL, STR_PROJECT_MANAGER_INFO } from '../../constants'
import Modal from 'react-modal'
import { ReactElement, useState, useEffect } from 'react'
import { Option, IStrategy } from '../../interfaces'
import Header from './header'
import CartBox from './cart/cartBox'
import Footer from './footer'
import MainContent from './mainContent'
import Description from './description'
import '../../styles/index.css'
import { 
    createEventList, 
    createEventTagList, 
    createWeaponTagList,
    createWeaponsList,
    createEventTagsList,
    createCategoriesList,
    calculate,
    modifyPdf,
} from '../../helpers'
import MobileDrawer from './MobileDrawer'

function AmmoOP(): ReactElement {
    const [catValue, setCatValue] = useState<Option | null>(null);
    const [chapterSelected, setChapterSelected] = useState<string>();
    const [eveOptions, setEveOptions] = useState(createEventList(catValue, chapterSelected));
    const [eveValue, setEveValue] = useState<readonly Option[]>([]);
    const [eventTags, setEventTags] = useState<string[]>([]);
    const [eventsOptions, setEventsOptions] = useState(createEventTagsList(eventTags));
    const [eventsValue, setEventsValue] = useState<Option | null>(null);
    const [is10Added, set10Added] = useState<boolean>(false);
    const [isEventsMulti, setIsEventsMulti] = useState<boolean>(false);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [iterationCount, setIterationCount] = useState<number>(1);
    const [open, setOpen] = useState<boolean>(true);
    const [pageSubtitle, setPageSubtitle] = useState<string[]>([]);
    const [quickSetClicked, setQuickSetClicked] = useState<boolean>(false);
    const [savedCalcs, setSavedCalcs] = useState<{ [charRep : string]: IStrategy[] }[]>([]);
    const [savedTitles, setSavedTitles] = useState<ReactElement[]>([]);
    const [tempCatValue, setTempCatValue] = useState<Option | null>(null);
    const [tempEveValue, setTempEveValue] = useState<readonly Option[]>([]);
    const [tempEventsValue, setTempEventsValue] = useState<Option | null>(null);
    const [tempWeaponValue, setTempWeaponValue] = useState<Option | null>(null);
    const [tooBig, setTooBig] = useState<boolean>(false);
    const [tooNarrow, setTooNarrow] = useState<boolean>(false);
    const [tooShort, setTooShort] = useState<boolean>(false);
    const [tooSmall, setTooSmall] = useState<boolean>(false);
    const [tooTall, setTooTall] = useState<boolean>(false);
    const [weaponCount, setweaponCount] = useState<number>(1);
    const [weaponTags, setWeaponTags] = useState<string[]>([]);
    const [weaponValue, setWeaponValue] = useState<Option | null>(null);
    const [isMobileDrawerShowing, setIsMobileDrawerShowing] = useState<boolean>(false);
    const [weaponOptions, setWeaponOptions] = useState(createWeaponsList(weaponTags));
    const [catOptions, setCatOptions] = useState(createCategoriesList(weaponValue, eventsValue, chapterSelected));
    
    useEffect(() => {
        setPageSubtitle([])
        for (let i = 0; i < ALL_CHAPTERS.length; i++)
            if (chapterSelected === ALL_CHAPTERS[i].name)
                setPageSubtitle([...pageSubtitle, `${ALL_CHAPTERS[i].tags[0]} - `, `${chapterSelected}`])
        if (chapterSelected) setCatOptions(createCategoriesList(weaponValue, eventsValue, chapterSelected));
        setWeaponTags(createWeaponTagList(chapterSelected))
        setEventTags(createEventTagList(quickSetClicked, setEventsValue, weaponValue, chapterSelected, catOptions))
        setWeaponValue(null)
        setCatValue(null)
        setEveValue([])
        setEventsValue(null)
        setweaponCount(1)
        setIterationCount(1)
    }, [chapterSelected])
    useEffect(() => {
        if (quickSetClicked){
            const tempWep = tempWeaponValue;
            const tempEvents = tempEventsValue;
            const tempCat = tempCatValue;
            const tempEve = tempEveValue;
            setWeaponValue(tempWep);
            setEventsValue(tempEvents);
            setCatValue(tempCat);
            setEveValue(tempEve);
        }
        else{
            setTempWeaponValue(null);
            setTempEventsValue(null);
            setTempCatValue(null);
            setTempEveValue([]);
        }
    }, [quickSetClicked])
    useEffect(() => {
        const doNothing = () => { return }
        doNothing()
    }, [pageSubtitle])
    useEffect(() => {
        setEveOptions(createEventList(catValue, chapterSelected));
    }, [catValue])
    useEffect(() => {
        setWeaponOptions(createWeaponsList(weaponTags));
        setEventsOptions(createEventTagsList(eventTags));
    }, [eventTags])
    useEffect(() => {
        setEventTags(createEventTagList(quickSetClicked, setEventsValue, weaponValue, chapterSelected, catOptions))
    }, [weaponValue])
    useEffect(() => {
        setCatOptions(createCategoriesList(weaponValue, eventsValue, chapterSelected));
    }, [eventsValue, weaponValue])
    useEffect(() => {
        set10Added(false)
        if (eveValue.length && chapterSelected){
            calculate(tooBig, is10Added, chapterSelected, eveValue, catValue, weaponCount, iterationCount, currentCalcs)
        }
    }, [weaponCount, iterationCount, eveValue])
    useEffect(() => {
        if (quickSetClicked) {
            setQuickSetClicked(false);
        }
        if (eveValue.length > 1)
            setIsEventsMulti(true)
        else
            setIsEventsMulti(false)
    }, [eveValue])
    useEffect(() => {
        if (weaponOptions.length === 1)
            setWeaponValue(weaponOptions[0])
    }, [weaponOptions])
    useEffect(() => {
        if (eventsOptions.length === 1)
            setEventsValue(eventsOptions[0])
    }, [eventsOptions])
    useEffect(() => {
        if (catOptions.length === 1)
            setCatValue(catOptions[0]);
    }, [catOptions])
    useEffect(() => {
        if (eveOptions.length === 1 && eveOptions[0].value != "You shouldn't see this")
            setEveValue([eveOptions[0]])
        else if (!quickSetClicked && !tempEveValue)
            setEveValue([]);
    }, [eveOptions])
    useEffect(() => {
        console.log(savedCalcs)
    }, [savedCalcs])

    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const currentCalcs: { [charRep : string]: IStrategy[] }[] = [];

    // This section checks screen dimensions for appropriate scaling
    // the resulting strings are appended to the classNames of components for sizing
    let tooBigStyle, tooBigModalStyle = {}
    let classAdd = "";
    if (tooSmall) classAdd = "small";
    let classTallAdd = "";
    if (tooTall && tooSmall) classTallAdd = "tall";
    if (tooShort && tooSmall) classTallAdd = "short";
    let classBigAdd = "";
    if (tooBig) {
        classBigAdd = "big"
        tooBigStyle = {
            minHeight : '3.5vh',
            fontSize: '40px'
        }
        tooBigModalStyle = {
            fontSize : '100px',
            height: 'fit-content',
        }
    }

    if ((screenWidth < 1250 || screenHeight < 700) && !tooSmall) {
        setTooSmall(true);
        setChapterSelected(undefined);
    } else if (screenWidth > 1250 && screenHeight > 700 && tooSmall) {
        setTooSmall(false);
    }
    
    if (screenWidth < 361 && !tooNarrow) {
        setTooNarrow(true);
    } else if (screenWidth > 361 && tooNarrow) {
        setTooNarrow(false);
    }
    
    if (screenHeight > 1000 && !tooTall) {
        setTooTall(true);
    } else if (screenHeight < 1000 && tooTall) {
        setTooTall(false);
    }
    
    if (screenHeight < 800 && !tooShort) {
        setTooShort(true);
    } else if (screenHeight > 800 && tooShort) {
        setTooShort(false);
    }

    if (screenHeight > 1400 && screenWidth > 2500 && !tooBig) {
        setTooBig(true);
    }

    // data passed through props to rendered components
    // could be changed to context if states are elevated to App.tsx??
    const dataObj = {
        tooBig : tooBig,
        tooBigStyle : tooBigStyle,
        
        tooNarrow : tooNarrow,
        tooSmall : tooSmall,
        isModalOpen : isModalOpen,
        constants : [ALL_CHAPTERS, ARMY_DODICS],
        chapterSelected : chapterSelected,
        open : open,
        savedTitles : savedTitles,
        pageSubtitle : pageSubtitle,
        savedCalcs : savedCalcs,
        eveValue : eveValue,
        catValue : catValue,
        eventsValue : eventsValue,
        weaponValue : weaponValue,
        classAdd : classAdd,
        classBigAdd : classBigAdd,
        classTallAdd : classTallAdd,
        isEventsMulti : isEventsMulti,
        weaponOptions : weaponOptions,
        modifyPdf : modifyPdf,
        setModalOpen : setModalOpen,
        setChapterSelected : setChapterSelected,
        setSavedCalcs : setSavedCalcs,
        setSavedTitles : setSavedTitles,
        setOpen : setOpen,
        setWeaponValue : setWeaponValue,
        setCatValue : setCatValue,
        setEveValue : setEveValue,
        eventsOptions : eventsOptions,
        setEventsValue : setEventsValue,
        catOptions : catOptions,
        eveOptions : eveOptions,
        setweaponCount : setweaponCount,
        weaponCount : weaponCount,
        iterationCount : iterationCount,
        setIterationCount : setIterationCount,
        currentCalcs : currentCalcs,
        is10Added : is10Added,
        setTempWeaponValue : setTempWeaponValue,
        setTempEventsValue : setTempEventsValue,
        setTempEveValue : setTempEveValue,
        setQuickSetClicked : setQuickSetClicked,
        setTempCatValue : setTempCatValue,
        isMobileDrawerShowing : isMobileDrawerShowing, 
        setIsMobileDrawerShowing : setIsMobileDrawerShowing
    }
    return(
        <div className="container" style={{width: "100vw", height: "100vh", overflow: "hidden"}}>
            <>{Header(dataObj)}</>
            <>{MainContent(dataObj)}</>
            <>{MobileDrawer(tooSmall, isMobileDrawerShowing, setIsMobileDrawerShowing)}</>
            <>{Description(dataObj)}</>
            <>{CartBox(dataObj)}</>
            <>{Footer(dataObj)}</>
                
            <Modal 
                isOpen={isModalOpen}
                ariaHideApp={false}
                onRequestClose={() => {setModalOpen(false)}}
                style={MODAL_STYLE_FIT_CONTENT}
                contentLabel="PM Information">
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', ...tooBigModalStyle }}>
                    <div>{STR_PROJECT_MANAGER_INFO}</div>
                    <a href={`mailto: ${STR_PROJECT_MANAGER_EMAIL}`}>{STR_PROJECT_MANAGER_EMAIL}</a>
                </div>
            </Modal>
        </div>
    )
}

export default AmmoOP;
